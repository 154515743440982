import { createStore } from 'vuex'
import FallosService from '../services/fallos.service'
const _FallosService = new FallosService()

const storeFallos = {
  state: {
    _fallos: [],
    _fallo: {},
    _fallosByTutela: {
      id: null,
      fechaImpugnacion: null,
      observaciones: null,
      nombre: null,
      paths: [],
      tipoDoc: null,
      numeroIdentificacion: null,
      Impugnacion: [{
        Fallo: {}
      }],
      createdByNameImpugnaciones: {
        fullName: null
      }
    }
  },
  getters: {
    fallos: state => state._fallos,
    fallo: state => state._fallo,
    fallosByTutela: state => state._fallosByTutela
  },
  mutations: {
    setFallos (state, payload) {
      state._fallos = payload
    },
    setFallo (state, payload) {
      state._fallo = payload
    },
    setFallosByTutela (state, payload) {
      state._fallosByTutela = payload
    },
    setUpdateFallo (state, payload) {
      state._fallo.base = payload.base
      state._fallo.observacion = payload.observacion
      state._fallo.tipo = payload.tipo
      state._fallo.fechaNotificacion = payload.fechaNotificacion
      state._fallo.fechaVencimiento = payload.fechaVencimiento
      state._fallo.paths = payload.paths
    },
    setDefault (state) {
      state._fallo = {}
    }
  },
  actions: {
    getPaginate ({ commit }, payload) {
      return _FallosService.paginate(payload).then(({ data }) => {
        commit('setFallos', data)
      })
    },
    search ({ commit }, payload) {
      return _FallosService.search(payload).then(({ data }) => {
        commit('setFallos', data)
      })
    },
    createFallo ({ commit }, payload) {
        return _FallosService.save(payload).then((response) => {
            return response
        })
    },
    findFallo ({ commit }, id) {
      return new Promise((resolve, reject) => {
        _FallosService.find(id).then(({ data }) => {
          commit('setFallo', data)
          resolve(data)
        })
      })
    },
    updateFallo ({ getters }, id) {
      return new Promise((resolve, reject) => {
        _FallosService.edit(getters.fallo, id).then(({ data }) => {
          resolve(data)
        })
      })
    },
    removeFallo ({ commit }, id) {
      return new Promise((resolve) => {
        _FallosService.remove(id).then((response) => {
          resolve(response)
        })
      })
    },
    findFalloByTutela ({ commit }, id) {
      return _FallosService.findFalloByTutela(id).then(({ data }) => {
        commit('setFallosByTutela', data)
        return true
      })
    }
  }
}
const store = createStore(storeFallos)
export default {
  namespaced: true,
  ...store
}
